<!--出入库汇总-->
<template>
    <div class="InOutSummary" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-select v-model="form.deptGroupCode" @change="handleSelectDeptGroup" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.deptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option
                                    v-for="item in meta.types"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="业务类型">
                            <el-select v-model="form.bizType">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.bizTypes"
                                    :label="item.value"
                                    :value="item.key"
                                    :key="item.key"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="是否结账">
                            <el-select placeholder="是否结账" v-model="form.settleStatus">
                                <el-option value="" label="请选择" />
                                <el-option value="0" label="未结账" />
                                <el-option value="1" label="已结账" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类目">
                            <el-select v-model="form.categoryCode">
                                <el-option label="请选择" value="" />
                                <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                                    <el-option
                                        v-for="category in group.sub"
                                        :label="category.name"
                                        :value="category.code"
                                        :key="category.code"
                                    />
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="品牌">
                            <el-select v-model="form.brandCode">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.brands"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.inOutSummary.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.inOutSummary.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                :row-style="rowStyle"
            >
                <el-table-column label="序号" prop="orderNum" width="60" fixed="left" v-if="showColumn('orderNum')" />
                <el-table-column
                    label="机构名称"
                    prop="deptName"
                    min-width="120"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    label="商品名称"
                    prop="sku.name"
                    key="sku.name"
                    width="180"
                    fixed="left"
                    v-if="showColumn('sku.name')"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sku">
                            {{ scope.row.sku.name }}
                        </span>
                        <span v-else-if="!scope.row.status" style="text-align: right"> 小计: </span>
                        <span v-else> 合计: </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品条码"
                    prop="sku.bars"
                    key="sku.bars"
                    width="120"
                    v-if="showColumn('sku.bars')"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.sku">
                            <span v-for="b in scope.row.sku.bars" :key="b">{{ b }}<br /></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" prop="sku.specs" min-width="100" v-if="showColumn('sku.specs')" />
                <el-table-column label="类目" prop="sku.category" width="100" v-if="showColumn('sku.category')" />
                <el-table-column label="单位" prop="sku.unit" width="80" v-if="showColumn('sku.unit')" />
                <el-table-column label="品牌" prop="sku.brand" width="80" v-if="showColumn('sku.brand')" />
                <el-table-column
                    label="是否结账"
                    prop="settleStatus"
                    key="settleStatus"
                    width="80"
                    v-if="showColumn('settleStatus')"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sku">
                            {{ scope.row.settleStatus ? '已结账' : '未结账' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="进项数量"
                    width="80"
                    prop="inCount"
                    :formatter="mCount"
                    align="right"
                    v-if="showColumn('inCount')"
                />
                <el-table-column
                    label="进项成本金额"
                    width="100"
                    prop="inCostMoney"
                    :formatter="mFour"
                    align="right"
                    v-if="showColumn('inCostMoney') && showPlanCostPrice()"
                />
                <el-table-column
                    label="进项金额"
                    width="100"
                    prop="inMoney"
                    :formatter="mFour"
                    align="right"
                    v-if="showColumn('inMoney') && showInPrice()"
                />
                <el-table-column
                    label="出项数量"
                    width="80"
                    prop="outCount"
                    :formatter="mCount"
                    align="right"
                    v-if="showColumn('outCount')"
                />
                <el-table-column
                    label="出项成本金额"
                    width="100"
                    prop="outCostMoney"
                    :formatter="mFour"
                    align="right"
                    v-if="showColumn('outCostMoney') && showPlanCostPrice()"
                />
                <el-table-column
                    label="出项金额"
                    width="100"
                    prop="outMoney"
                    :formatter="mFour"
                    align="right"
                    v-if="showColumn('outMoney') && showOutPrice()"
                />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import BizTypeUtils from '../../../js/BizTypeUtils';
import EfStartDate from '../../components/EfStartDate';
import EfEndDate from '../../components/EfEndDate';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'InOutSummary',
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: '',
                deptCode: '',
                bizType: '',
                settleStatus: '',
                bizCode: '',
                search: '',
                categoryCode: '',
                brandCode: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/report/inOutSummary',
                queryDeptByGroup: '/system/dept/deptTree',
            },
            current: {
                typeIdx: 3,
            },
            meta: {
                deptGroups: [],
                types: [],
                bizTypes: {},
                categories: [],
                brands: [],
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GoodsBrands(this, (rst) => {
            _this.meta.brands = rst;
        });
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
        UrlUtils.DeptGroup(_this, (rst) => {
            _this.meta.deptGroups = rst || [];
            _this.form.deptGroupCode = _this.meta.deptGroups[0].code;
            _this.handleSelectDeptGroup(_this.form.deptGroupCode);
        });
    },
    methods: {
        handleQuery() {
            const _this = this;

            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            UrlUtils.Export(this, '出入库汇总', '/report/inOutSummary/export', this.form);
        },
        handleSelectDeptGroup(groupCode) {
            const _this = this;
            _this.meta.types = [];
            _this.form.deptCode = '';
            if (groupCode) {
                UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + groupCode, (rst) => {
                    _this.meta.types = rst || [];
                    _this.form.deptCode = (_this.meta.types[0] || {}).code || null;
                    _this.handleQuery();
                });
                const deptGroup = _this.meta.deptGroups.find((c) => c.code == groupCode);
                this.changeBizType(deptGroup.deptType);
            }
        },
        changeBizType(deptType) {
            this.meta.bizTypes = BizTypeUtils.bizTypes(deptType);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        rowStyle(row) {
            const style = {};
            if (typeof row.row.sku == 'undefined' && !row.row.status) {
                Object.assign(style, {
                    background: '#f9f9f9',
                });
            }
            if (typeof row.row.status != 'undefined') {
                Object.assign(style, {
                    fontWeight: 'Bolder',
                });
                if (row.row.status) {
                    Object.assign(style, {
                        fontSize: '14px',
                    });
                }
            }
            return style;
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
    },
};
</script>

<style scoped>
.InOutSummary .el-form-item {
    margin-bottom: 0;
}
</style>
